import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {Element} from 'react-scroll';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  return (
    <Element
      {...props}
      className={outerClasses}
      name={'what-we-do'}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Smart infrastructure
                  </div>
                <h3 className="mt-0 mb-12">
                  IT Infrastructure
                  </h3>
                <p className="m-0">
                  Our team has more than 10 years of high-caliber technical expertise providing managed IT services and IT infrastructure solutions.
                  We work with you to audit your existing hardware and software infrastructure to identify performance improvement opportunities,
                  as well as IT security and risks.

                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/it-infrastructure.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Better support
                  </div>
                <h3 className="mt-0 mb-12">
                  End-User Support
                  </h3>
                <p className="m-0">
                  In a perfect world, every member of your IT team would have the skills and time to not only forge ahead with your
                  growth plans, but also support your day-to-day end users—all without needing additional support.
                  In the real world, technology and the business itself are changing faster than any individual can fully prepare for.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/it-support.svg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Application Support
                  </div>
                <h3 className="mt-0 mb-12">
                  App Support and Maintenance
                  </h3>
                <p className="m-0">
                  Your business apps: ERP, Point of Sale, CRM are the most important tools of your company.
                  Our experts have many years of experience in world-class and local applications and will be able to support you, no matter who your provider is.
                  Furthermore, we are able to exctract your most valuable data from your databases and present them how, when and where you need!
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/it-maintenance.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Chose the solution yourself
                  </div>
                <h3 className="mt-0 mb-12">
                  Tailored IT Solutions
                  </h3>
                <p className="m-0">
                Whether it's a website, an API, an application or even a simple report, be sure that our team will always have a solution for you. 
Whether you are switching providers or applications, offices or IT infrastructure MonkeyTech will make the process easier and smoother. Apart from operational, hands-on work we can also be your IT business consultant, acting as bridge between you and providers to help you organize your tech ideas, express clearly your needs and ensure the solutions bring added value to your business.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/tailored-it-solutions.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </Element>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
