import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-scroll';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <Link to="about"
                activeClass={"active"}
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
          >About us</Link>
        </li>
        <li>
          <Link to="services"
                activeClass={"active"}
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
          >Services</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;
