import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from "../elements/Button";
import * as emailjs from "@emailjs/browser";
import {Element} from 'react-scroll';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const form = useRef();

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_oj1f8bl', 'template_iylorjo', form.current, 'gV4QkU5rsj-uXA2yt')
        .then((result) => {
          form.current.reset();
          alert("Email sent successfully")
        }, (error) => {
            alert("Email not sent")
        });
  };

  return (
    <Element
      {...props}
      className={outerClasses}
      name={'contact'}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Get in touch
              </h3>
            <p className="m-0">
              Please fill out the form below to send to us an email and we will get back to you as soon as possible.
              </p>
          </div>
        </div>

        <div
          className={innerClasses}
          style={{paddingTop:"0",paddingBottom: "52px", justifyContent:'center'}}
        >
          <form ref={form} onSubmit={sendEmail}>
            <div className="cta-action">

              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div className={'mr-16 mb-16'}>
                  <Input id="name" type="text" label="Subscribe" name={'name'} labelHidden hasIcon="right" placeholder="Name*"
                         required
                  >
                  </Input>
                </div>

                <div className={'mb-16'}>
                  <Input id="email" type="email" label="Subscribe" name={'email'} labelHidden hasIcon="right" placeholder="E-mail*"
                         required>
                  </Input>
                </div>
              </div>

              <div style={{display: "flex"}}>
                <div className={'mr-16 mb-16'}>
                  <Input id="company" type="text" label="Subscribe" name={'company'} labelHidden hasIcon="right" placeholder="Company*"
                  required>
                  </Input>
                </div>

                <div className={'mb-16'}>
                  <Input id="phone" type="text" label="Subscribe" name={'phone'} labelHidden hasIcon="right" placeholder="Phone No.*"
                         required>
                  </Input>
                </div>
              </div>

              <div style={{display:"flex", justifyContent: 'flex-end'}}>
                <button type={'submit'} style={{textDecoration: 'none', border: 'none', padding:0,background:'black',
                borderRadius:'3px'}}>
                  <Button tag="a" color="dark" wideMobile>
                    <span>Submit</span>

                    <span className={'pt-4 ml-12'} style={{paddingTop:"6px"}}>
                  <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="white" />
                </svg>
                </span>
                  </Button>
                </button>
              </div>
            </div>
          </form>

        </div>

      </div>
    </Element>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
