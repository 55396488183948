import React, {useState} from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Services from "../components/sections/Services";
import Button from "../components/elements/Button";
import Scroll from 'react-scroll'

const Home = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        }
        else if (scrolled <= 300){
            setVisible(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);

  return (
    <>
      <Hero className="illustration-section-01" />

        <div style={{
            display: visible ? 'inline' : 'none',
            position: 'fixed',
            right: '30px',
            bottom: '60px',
            height: '20px',
            fontSize: '3rem',
            zIndex: 1,
            cursor: 'pointer',
        }}>
            <Button tag="a" color="primary" style={{
                padding: 0,
                paddingTop: '12px',
                paddingLeft: '10px',
                paddingRight: '12px',
                borderRadius: '200px',
            }} wideMobile onClick={() => {
                const scroll = Scroll.animateScroll;
                scroll.scrollToTop();
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" fill="currentColor"
                     className="bi bi-arrow-up" viewBox="0 0 16 24">
                    <path fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                </svg>
            </Button>
        </div>

      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Services topDivider />
      <Cta split />
    </>
  );
}

export default Home;
