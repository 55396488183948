import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import {Element} from 'react-scroll';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Services = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  return (
    <Element
      {...props}
      className={outerClasses}
      name={'services'}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-footer mb-16">
                  <span className="testimonial-item-name text-color-high">Business Applications Support</span>
                </div>

                <div className="testimonial-item-footer has-top-divider">
                  <p className="text-xs mb-0">
                    <ul className={'mt-12'}>
                      <li>Microsoft SQL Server Administration and Development</li>
                      <li>Reporting (BI)</li>
                      <li>Integration Services & Big Data</li>
                      <li>Maintenance</li>
                      <li>Data Warehouse</li>
                    </ul>
                      </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">

                <div className="testimonial-item-footer mb-16">
                  <span className="testimonial-item-name text-color-high">Network Configuration & Support</span>
                </div>

                <div className="testimonial-item-footer has-top-divider">
                  <p className="text-xs mb-0">
                    <ul className={'mt-12'}>
                      <li>Routers</li>
                      <li>Switches</li>
                      <li>Firewalls</li>
                      <li>WLan Controllers</li>
                      <li>Access Points</li>
                      <li>Vlan Configuration</li>
                      <li>VOIP PBX Configuration</li>
                      <li>DHCP configuration</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">

                <div className="testimonial-item-footer mb-16">
                  <span className="testimonial-item-name text-color-high">Processing Units-Installation, Configuration, Maintenance</span>
                </div>

                <div className="testimonial-item-footer has-top-divider">
                  <p className="text-xs mb-0">
                    <ul className={'mt-12'}>
                      <li>Servers</li>
                      <li>Mail servers</li>
                      <li>PCs</li>
                      <li>Servers OS Setup & Configuration</li>
                      <li>Workstation</li>
                      <li>Client OS Setup & Configuration</li>
                      <li>FTP Server</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-footer mb-16">
                  <span className="testimonial-item-name text-color-high">Security & Business Continuity</span>
                </div>

                <div className="testimonial-item-footer has-top-divider">
                  <p className="text-xs mb-0">
                    <ul className={'mt-12'}>
                      <li>Backups Database</li>
                      <li>Backups Server System State</li>
                      <li>Backups Network Device Configuration</li>
                      <li>Host Intrusion Prevention & Port Blocker</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-footer mb-16">
                  <span className="testimonial-item-name text-color-high">Software Development</span>
                </div>

                <div className="testimonial-item-footer has-top-divider">
                  <p className="text-xs mb-0">
                    <ul className={'mt-12'}>
                      <li>Systems architecture</li>
                      <li>Application development</li>
                      <li>Quality assurance and testing</li>
                      <li>Design and development</li>
                      <li>Go-to-market delivery</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}

Services.propTypes = propTypes;
Services.defaultProps = defaultProps;

export default Services;
